.Assessment-Container {
    background-color: white;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    margin: 2%;
    height: 90%;
    width: 100%;
    max-width: 1920px;
    overflow: hidden;
}

