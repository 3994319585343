.Section-Container {
    width: 98%;
    height: 98%;
    display: flex;
    margin: 1%;
}

.SectionText-Container {
    width: 98%;
    height: 98%;
    margin: 1%;
    overflow-y: auto;
}

.SectionDivider-Container {
    width: 98%;
    height: 98%;
    margin: 1%;
}

.SectionQuestion-Container {
    width: 98%;
    height: 98%;
    margin: 1%;
    overflow-y: auto;
}

.SectionLoading-Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
